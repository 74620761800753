html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Urbanist", sans-serif;
  margin-bottom: 1rem;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

section {
  margin-top: 2rem;
  padding: 2rem 0;
}

#__next {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.design {
  position: fixed;
  top: 0;
  left: 0;
  overflow: visible;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}

main {
  flex-grow: 1;
  padding: 2em 0;
  display: flex;
  flex-flow: column;
}

main > .MuiContainer-root {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

sub {
  font-size: 50%;
}

footer {
  text-align: center;
  padding: 0.5em;
}

footer .mantine-Text-root {
  margin-top: 10px;
}

footer svg {
  height: 15px;
  margin-bottom: -3px;
}

footer a {
  font-weight: bold;
}

b {
  color: #1db954;
}

img.theme-image {
  max-width: 100%;
}

img.small {
  max-height: 12em;
}
